.itemContainer h1{
text-transform: uppercase;
}

.cont_details_main{
  display: flex;
  flex-direction: column;
}

.cont_details{
  display: inline-flex;
    align-items: baseline;
    gap: 0.6rem;
}

.cont_details h4{
  font-weight:bold;
}

.text-justify {
  text-align: justify;
}

.font-h5{
  font-size: 1.5rem;
    color: white;
    background-color: #002569;
}

.container-grid-img {
  display: grid;
  grid-template-columns: repeat (3, 1fr);
  /*  grid-auto-rows: 380px; */
  width: 31.25rem;
  gap: 0.8rem;
}
.container-grid-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.container-grid-img_first {
  grid-column: span 3;
  grid-row: span 2;

}

.container-grid-img_first.open{
  width:100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  visibility: visible;
  opacity: 1;
  transform: scale(0.8);
  overflow: hidden;
  z-index: 999;
}

.container-grid-img video:first-child{
  grid-column: span 3;

}



.display-grid {
  display: grid;
  grid-template-columns: 2fr 1.5fr;
}

.span-3 {
  grid-column: span 3;
}

@media only screen and (max-width: 768px) {
  .display-grid {
    display: grid;
    grid-template-columns: auto;
    gap: 2rem;
  }
}

@media only screen and (max-width: 590px) {
  .span-3 {
    grid-column: span 2;
  }
}

.form-reservation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1.5rem;
  text-transform: uppercase;
  gap: 2rem;
  font-weight: 500;
  color: grey;
}
.date-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}
.date-form p{
  border-bottom: 2px dotted;
}

.time-form_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 2rem;
}

.time-form {
  display: flex;
  gap: 0.5rem;
  justify-content: space-around;
  z-index: 0;
}

.time-form_container p{
  border-bottom: 2px dotted;
}
.container_tarifas{
  max-width: max-content;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.6rem;
    margin: 0 auto;
}

.quantity-form_container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
}

.quantity-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

input[type="number"] {
  text-align: center;
}

.react-datepicker__input-container input{
  text-align: center;
}

#quantity{
  max-width: min-content;
    margin: 0 auto;
}