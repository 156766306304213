.hero {
  width: 100%;
  height: 80vh;
  top: 7rem;
  position: relative;
}

.tresTextosInicio{
text-align: center; 
width: auto; 
margin: auto;    
color: white;   
font-size: 17px;
font-weight: bold
}

.hero::before {
  content: "";
  background: url("../assets/Portada-SERVICIOS.png")
    no-repeat center center/cover;
  height: 80vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

}
/*
.hero::after {
  content: "";
  background: url("../assets/monta.png") no-repeat center center/cover;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 12rem;
  left: 0;
  bottom: -11.375rem;
}
*/
.heroContent_container {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.heroContent {
  background-color: #f2f1f1;

  max-width: 41rem;
  padding: 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0.0187rem 0.0313rem 0.3125rem;
  filter: opacity(0.91);
}

.slick-track { 	display: flex !important; }  .slick-slide { 	height: inherit !important; 	display: flex !important; 	justify-content: center; 	align-items: center; }

@media only screen and (min-width:1024px) {
  .hero {
    width: 100%;
    height: 80vh;
    top: 7rem;
    position: relative;
  }

  .tresTextosInicio{
    width: auto;  
    font-size: 40px;
    }
  
}