 @media only screen and (max-width: 1200px){
  html,body{
    font-size: 15px;
  }
  
} 



@media (min-width: 1200px) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .heroContent{
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 1078px) {
  html,
  body {
    font-size: 14px;
  }
  .navbar-nav {
    font-size: 1rem;
  }

  .navicons li {
    padding: 0.4rem;
    font-size: 1rem;
  }

  .btn-nav {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 991px) {
  .nav-link {
    font-size: 1.3rem;
    padding: 1rem;
  }

  html,
  body {
    font-size: 13px;
  }

 

  .navbar-collapse {
    background: #f8f9fa;
    height: 100vh;
    padding: 2.5rem;
    margin-top: 3rem;
  }

  .navicons {
    justify-content: center;
    
  }

  .navicons li {
    padding: 0.6rem;
    font-size: 1.5rem;
  }

  .flecha1,
  .flecha2 {
    height: 7.3rem;
  }

  .grid_galeria {
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
  .img2 {
    grid-row: 3/4;
    grid-column: 1/2;
  }

  .img3{
    grid-row: 3/4;
    grid-column: 2/3;
}
}

@media only screen and (max-width: 768px) {
  .headerTitle {
    transform: translate(0px, -46rem);
  }
  html,
  body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 590px) {
  html,
  body {
    font-size: 10px;
  }



  img.headerImg {
    height: 70vh;
}

  .flecha1,
  .flecha2 {
    height: 5.3rem;
  }
}

/* @media only screen and (max-width: 450px) {
  html,body{
    font-size: 6px;
  }
} */
