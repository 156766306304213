.contacto_header{
    font-size: 1.5rem;
    color: var(--grey-color);
    margin-top: 10rem;
    margin-bottom: 4rem;
}

.contacto_form{
    width: 31.25rem;
    height: 25rem;
    background-color: #87a8cd;
    border-radius: 3px;
    padding: 1rem;
    min-width: 15.625rem;

}

.contacto_form h4 {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 1.7rem;
}

.mapa{
    width: 1100px;
    height: 500px;
}

@media only screen and (max-width: 1200px) {
    .mapa{
        width: 650px;
        height:350px;
    }
}

@media only screen and (max-width: 768px){
    .mapa{
        width: 500px;
        height:300px;
    }
}

@media only screen and (max-width: 590px){
    .mapa{
        width: 350px;
        height:200px;
    }
}