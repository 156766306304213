.hero_Nos {
  width: 100%;
  height: 60vh;
  top: 3rem;
  position: relative;
}

.hero_Nos::before {
  content: "";
  background: url("../assets/Portada-NOSOTROS.png")
    no-repeat center center/cover;
  height: 60vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  filter: brightness(0.7);
}

/*
.hero_Nos::after {
  content: "";
  background: url("../assets/monta.png") no-repeat center center/cover;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 12rem;
  left: 0;
  bottom: -10.375rem;
}
*/

.tresTextosNosotros{
  text-align: center; 
  width: auto; 
  margin: auto;    
  color: white;   
  font-size: 15px;
  font-weight: bold
  }

.heroContent_container_Nos {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}





.containerNos {
  height: 14rem;
  margin-top: 5rem;
}
.containerNos h2 {
  font-size: 3rem;
}

.containerNos ul {
  list-style: none;
}

.containerNos_text {
  font-size: 1.7rem;
  color: var(--blue1-color);
  font-weight: 600;
  margin: 1rem;
}

.containerNos_vision {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.containerNos_vision_a {
  width: 31rem;
  color: #002669;
  background: #e6eaed;
  padding: 1.4rem;
  margin: 1rem;
}

.containerNos_vision_a p {
  text-align: justify;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.5rem;
  line-height: 2rem;
}

.containerNos_vision_a h5 {
  font-size: 2rem;
}

.containerNos_vision_b {
  width: 31rem;
  color: #002669;
  background: #e6eaed;
  padding: 1.4rem;
  margin: 1rem;
}

.containerNos_vision_b h5 {
  font-size: 2rem;
}

.containerNos_vision_b ul {
  font-size: 1.1rem;
  text-align: justify;
  padding: 0.5rem;
  line-height: 1.625rem;
  list-style: none;
}

.containerNos_vision_b li {
  margin: 1rem 0px;
  font-weight: 500;
}

@media only screen and (min-width:1024px) {
  
  .hero_Nos {
    height: 80vh;
  }

  .hero_Nos::before {
    height: 90vh;
  }
  .tresTextosNosotros{
    width: auto;  
    font-size: 40px;
    }
 
    .containerNos {
      margin-top: 8rem;
    }
}