.FloatIcon-Wh {
    bottom: 2.5rem;
    right: 1.25rem;
    opacity: 1;
    transition: opacity 0.5s ease 0s;
    box-sizing: border-box;
    direction: ltr;
    text-align: right;
    position: fixed !important;
    z-index: 1 !important;
    animation: shake 2s infinite;
  }
  
  @keyframes shake {
    10% {
      transform: scale(1.05) rotate(5deg);
    }
    20% {
      transform: scale(1.05) rotate(-5deg);
    }
    30% {
      transform: scale(1.05) rotate(5deg);
    }
    40% {
      transform: none;
    }
  }
  
  .FloatIcon {
    margin-bottom: 0;
    margin-left: 0.1875rem !important;
  }
  
  .FloatIcon img {
    width: 4.625rem;
    height: 4.625rem;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }


  
