


.dropdown-menu {
    background: #053f9c !important;
    width: 12.5rem;
    position: absolute;
    top: 5rem;
    list-style: none;
    text-align: start;
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 1rem;
  }
  
  .dropdown-menu li {
    background: #053f9c;
    cursor: pointer;
  
  }
  
  .dropdown-menu li:hover {
    background: #5cabff;
  }
  
  .dropdown-menu.open {
    display: block;
    text-transform: uppercase;
    text-align: left; 
     top: 3.5rem;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #000;
    padding: 1rem;
    overflow: hidden;
  }
  
  @media screen and (max-width: 940px) {
    .fa-caret-down {
      display: none;
    }
  
    .dropdown-menu {
      width: 12rem;
      left: 27.5rem;
      top: 17.5rem;
    }
  
    .dropdown-menu.open {
      display: contents;
    }
  
    .dropdown-menu li {
      background: none;
      margin-top: 1.1rem;
      font-size: 1.3rem;
      text-align: center;
    }
  }
  

  .logo {
  transition: all 1s;
  height: 5rem;

  }

  .logo-sm {
    transition: all 1s;
    height: 4.5rem;

    }
  
  .NavBar-icons{
  display: none;
  font-size: 2rem;
  
  }
  
  
  
  .NavBar-icons::before {
    padding: 1.875rem;
  }
  
 
  .active {
    border: solid 0.075rem #685e5eb3;
    border-radius: 0.25rem;
    padding:0.2rem;
  }
  
  
  
  
  .navbar {
    position: fixed !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 2;
    font-size: 1.05rem;
    transition: all 1s;
  }

  .navbar-bcg {
    background: #bac9db !important;
    height: 6rem;
   
      font-size: 0.95rem;
      transition: all 1s;
    
  }
  
  
  
  .nav-menu {
    display: flex;
    margin-right: 1rem;
    align-items: flex-end;
  }
  
  .nav-item {
    text-decoration: none;
    color: #000;
    position: relative;
    padding: 1rem;
    transition: all 1s;
  }
  
  .hamburger {
    display: none;
  }
  
 
  
  @media screen and (max-width: 940px) {
    .nav-menu {
      position: fixed;
      left: -100%;
      top: 15%;
      flex-direction: column;
      justify-content: center;
      background-color: #f1f2f3;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: 0.4s;
      align-items: center;
    }
  
    .nav-menu.active {
      left: 0;
    }
  
    .nav-item {
      margin: 1.5rem;
      font-size: 1.8rem;
      justify-content: center;
    }
  
    .nav-item:hover {
transform: scale(1.01);    }
  
    .hamburger {
      display: flex;
      padding-left: 0.625rem;
      cursor: pointer;
      position: fixed;
    left: 0;
    }
  
   .logo {
    display: none;
  } 
    .NavBar-icons{
      display: flex;
      text-decoration: none;
    }

    .navicons {
      display: inline-block;
    }

}