/* HEADER */

.headerImg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: opacity(0.9);
/*   position: absolute;
 */}

.headerImg2 {
  width: 100%;
/*   position: relative;
 */  z-index: 1;
  top: 30rem;
  height: 15rem;
  transform: translate(0px, -8rem);
}

.headerTitle {
  background-color: #f2f1f1;
/*   position: relative;
 */  max-width: 41rem;
  padding: 1.5rem;
  margin: 0 auto;
  border-radius: 0.25rem;
  box-shadow: 0.0187rem 0.0313rem 0.3125rem;
  filter: opacity(0.91);
  transform: translate(0px, -38rem);
}

.headerTitle_a {
  font-size: 3rem;
  text-transform: uppercase;
  text-shadow: 0.125rem 0.0625rem 0.125rem #787171f5;
}

.headerTitle_b {
  font-family: "Montez", cursive;
  text-transform: capitalize;
  font-size: 4rem;
  color: var(--blue1-color);
}

.headerTitle_c {
  text-transform: uppercase;
  color: var(--grey-color);
  font-size: 1.5rem;
  margin: 0.5rem;
}

.headerTitle_btn {
  width: 8rem;
  height: 2.8rem;
  border-radius: 0.75rem;
  border: solid 0.0625rem #b6cee1;
  box-shadow: 0.0313rem 0.0437rem 0.0938rem;
  margin: 0 auto;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor:pointer;
}

.headerTitle_btn:hover{
  scale: 1.006;
  border: solid 0.0625rem #588fb7;
}



.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 4rem;
}


.container-lg-header{
  margin-top: 15rem;
  text-transform: uppercase;
}

.container_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0.5rem;
}

.container_cards div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-title-header {
  color: #fff;
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
}

.card-title-header span{
  margin-left: 0.5rem;
  align-self: center;
  display: flex;
  font-size: 1.3rem;
  cursor: pointer;
}



.container_cards img {
  width: 21rem;
  height: 15.5rem;
  object-fit: cover;
  filter: brightness(0.6);
}

.container_cards img:hover {
  filter: brightness(0.2);
  cursor: pointer;
}

.card-img-overlay {
  text-align: left;
  padding: 1.3rem;
}

.card-text {
  font-size: 0.85rem;
  color: #fff;
}

.text-display{
  opacity: 1;
  transition: all 2s;
}

.text-display-false{
  opacity: 0;
  transition: all 1s;

}