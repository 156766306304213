.hero_gal{
    width: 100%;
  height: 80vh;
  top: 3rem;
  position: relative;
}

.hero_gal::before {
    content: "";
    background: url("../assets/ush-am.jpg")
      no-repeat center center/cover;
    height: 90vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(0.7);
  
  }
  
  .hero_gal::after {
    content: "";
    background: url("../assets/monta.png") no-repeat center center/cover;
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 12rem;
    left: 0;
    bottom: -10.375rem;
  }
  
  .heroContent_container_gal {
    height: 100%;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  
  .heroContent_gal {
    max-width: 41rem;
    padding: 1.5rem;
    margin: 0 auto;
    color: #fff;
    width: 28rem;
    text-align: center;
  }
  






/* .headerTitle_galeria{
    max-width: 41rem;
    padding: 1.5rem;
    margin: 0 auto;
    transform: translate(0px, -43rem);
    color: #fff;
    width: 28rem;
    text-align: center;
} */


/* Grid */

.gallery{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    column-width: 30%;
    -webkit-column-width: 30%;
    -moz-column-width: 30%;
    padding: 0 10px;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 3.125rem;
}

.gallery .pics{
    cursor: pointer;
    padding: 0.3rem;
    -webkit-transition: all 350ms ease;

    transition: all 350ms ease;
    margin: 0.4rem;
    width: 100%;
    height: 100%;
}
.gallery .pics:hover{
    filter: opacity(0.6);
}

.model{
    width:100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #002568db;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;

}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

}

.model img{
    width: 70%;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.grid_galeria{
    display: grid;
    grid-template-rows: repeat(4,1fr) ;
    grid-template-columns: repeat(4,1fr);
    gap: 0.5rem;
    

}

.grid_galeria img {

   width: 100%;
    height: 100%;
    object-fit: cover; 
    cursor: pointer;
    transition: all 350ms ease;
}

.grid_galeria img:hover{
    filter: opacity(.8);
}



.img1{
    grid-row: 1/3;
    grid-column: 1/3;
}

.img2{
    grid-row: 1/2;
    grid-column: 3/4;
}

.img3{
    grid-row: 1/2;
    grid-column: 4/5;
}

.img4{
    grid-row: 2/3;
    grid-column: 3/4;
}

.img5{
    grid-row: 2/3;
    grid-column: 4/5;
}


.img6{
    grid-row: 3/4;
    grid-column: 1/2;
}

.img7{
    grid-row: 4/5;
    grid-column: 1/2;
}

.img8{
    grid-row: 3/5;
    grid-column: 2/4;
}

.img9{
    grid-row: 3/4;
    grid-column: 4/5;
}

.img10{
    grid-row: 4/5;
    grid-column: 4/5;
}

.galeria_title{
    margin-top: 17rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.galeria_title h2{
    font-weight: 600;
    font-size: 2.5rem;

}

@media (max-width:991px){
    .gallery{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    
}
}

@media (max-width:480px){
    .gallery{
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    column-width: 100%;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    
}
}