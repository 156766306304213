.headerTitle_blog{
    max-width: 41rem;
    padding: 1.5rem;
    margin: 0 auto;
    transform: translate(0px, -43rem);
    color: #fff;
    width: 28rem;
    text-align: center;
    background: #f1f1f126;
    border-radius: 10px;
}

.headerTitle_a_blog{
    font-size: 3rem;
    text-transform: uppercase;
    text-shadow: 0.125rem 0.0625rem 0.125rem #787171f5;
}

.headerTitle_b_blog{
    font-family: "Montez", cursive;
    text-transform: capitalize;
    font-size: 4rem;
}

.c-container{
    position: relative;
}

.c-container:hover {
    scale: 1.005;
    filter: drop-shadow(2px 4px 6px rgb(126, 125, 125));
  }


  

    .overlay {
        position: absolute;
        
        height: 55%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
       color: #ffffff;
        font-weight: 600;
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .c-container img:hover{
    filter: contrast(0.5);  }
   
      .overlay p{
        font-size: 1.4rem;
      }
      .card-img-blog:hover{
        filter: contrast(0.5);  

      }

      
      .c-container:hover .overlay {
        opacity: 1;
        
      }

.cardBlog{
    color:#000;
    text-align:left;
}

.c-title{
    margin:0.5rem;
}

.c-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin: 0.5rem;
}

.header_blog{
    margin-top: -17rem;
    color: var(--grey-color);
    font-size: 3rem;
}

.card-img-top{

    height: 14.4375rem;
    object-fit: cover;

}

.blog-i-text{
    line-height: 1.8rem;
    font-size: 1.2rem;
}