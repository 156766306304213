.btn-supr {
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.CartItems {
  margin-top: 4rem;
}

.CartItems h5 {
  font-size: 1.5rem;
}

.CartItems p {
  color: black;
  font-size: 1.1rem;
}

.CartItems-price {
  height: 7rem;
  display: grid;
  place-content: center;
}
.CartItems-price p {
  font-size: 2rem;
}

.cartNumber {
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  font-weight: 700;
  background-color: #f72222;
  width: 1.2rem;
  height: 1.2rem;
  right: -0.1rem;
  top: 1.01rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  animation: shake 3s infinite;
}

.CartButtons {
  margin: 2rem;
}

@keyframes shake {
  10% {
    transform: scale(1.05) rotate(3deg);
  }
  20% {
    transform: scale(1.05) rotate(-3deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: translateY(1px);
  }
}

.form-cart {
  width: 40rem;
  margin: 4rem auto;
}

.Cart-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}

.Cart-container-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
  justify-content: center;
  margin-top: 4rem;
}

@media (max-width: 1200px) {
  .Cart-container-flex {
    justify-content: start;
  }
}
