.hero_svs {
  width: 100%;
  height: 80vh;
  top: 3rem;
  position: relative;
}

.hero_svs::before {
  content: "";
  background: url("../assets/Portada-SERVICIOS.png")
    no-repeat center center/cover;
  height: 90vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  filter: brightness(0.7);
}

.hero_svs::after {
  content: "";
  background: url("../assets/monta.png") no-repeat center center/cover;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 12rem;
  left: 0;
  bottom: -11.375rem;
}

.heroContent_container_svs {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.heroContent_svs {
  background-color: #f2f1f1;

  max-width: 41rem;
  padding: 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0.0187rem 0.0313rem 0.3125rem;
  filter: opacity(0.91);
}

.headerTitle_svs {
  max-width: 44rem;
  padding: 1.5rem;
  margin: 0 auto;
  color: #fff;
  width: 40rem;
  text-align: center;
  background: #f1f1f126;
  border-radius: 10px;
}

.headerTitle_a_svs {
  font-size: 3.5rem;
  text-transform: uppercase;
  text-shadow: 0.125rem 0.0625rem 0.125rem #787171f5;
}

.headerTitle_b_svs {
  font-family: "Montez", cursive;
  text-transform: capitalize;
  font-size: 5rem;
}

.btn_svs {
  background: #002669;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 1rem;
  width: 8rem;
}

.heroContent_box {
  background: white;
  width: 35rem;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  height: 35%;
  border-radius: 0.3125rem;
  flex-wrap: nowrap;
  margin-top: 4rem;
}

.btn-search {
  background-color: #002669;
  margin: 0.5rem;
}

.btn-search:hover {
  background-color: rgba(0, 39, 105, 0.8);
}

.card-body {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  vertical-align: baseline;
}

.container-ExcSearch{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}



.container-lg-svs {
  margin-top: 15rem;
  color: #2c3e53;
}

.container-lg-svs h1 {
  text-transform: uppercase;
}

.card-svs {
  cursor: pointer;
  cursor: pointer;
  margin-right: 1.5rem;
  margin-top: 2.5rem;
  height: 20rem;
  margin-bottom:1rem; 
}

.card-svs:hover {
  scale: 1.005;
  filter: drop-shadow(2px 4px 6px rgb(126, 125, 125));
}

.itemContainer > h1 {
  font-size: 3rem;
  font-weight: 700;
}

.itemContainer p {
  font-size: 2rem;
  color: var(--blue1-color);
  font-weight: 700;
}

.itemContainer-img img {
  width: 31.25rem;
}

.containerItem-total {
  border: solid 4px #6573a4;
  height: auto;
  padding: 1rem;
}

.dropdw-svs {
  color: black;
  font-size: inherit;
  font-weight: 500;
}

.dropdw-svs:hover {
  background: #628bb6;
}


.card-body-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

#quantity{
  border-radius:4px;
}