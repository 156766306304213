/* Footer */

.footerContainer {
  position: relative;
    margin-top: 6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 3rem;
}

.footerContainer::before{
  content: '';
  background: url('../assets/olas3.png') no-repeat center center/cover;
  width: 100%;
  position: absolute;
  z-index: -1;
  height: 25rem;
  left: 0;
}

.footerContainer li {
  list-style: none;
}

.footerContainer_in {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #e6eaed;
  /*   top: 13.75rem;
 */
  flex-wrap: nowrap;
  margin: 0rem;
}

.IconsContainer {
  display: flex;
  justify-content: space-evenly;
  margin: 0.5rem 0px 1.3rem;
  padding: 0;
}

.IconsContainer li {
  padding: 0.5rem;
  font-size: 1.1rem;
}


.IconsContainer li:hover{
  transform: scale(1.15);
}

.IconsContainer li a:hover{
  color:#bac9db;
 
}

.menuContainer_main {
  display: flex;
  justify-content: space-evenly;
  width: 16.875rem;
  text-align: initial;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 500;

}

.menuContainer li a:hover{
  color:#bac9db;
}