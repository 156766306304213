@import url("https://fonts.googleapis.com/css2?family=Montez&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blue1-color: #002669;
  --grey-color: #857676;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #f2f1f1;
}
a {
  text-decoration: none;
  color: inherit;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navbar {
  position: fixed !important;
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  line-height: 1.0625rem;
  letter-spacing: 0.0313rem;
  top: 0;
  background: #f2f1f1;
}

.navbar ul {
  list-style: none;
  font-size: 0.9rem;
  /* padding-left: 4.5rem; */
}

.navbar-bcg {
  background: #bac9db !important;
  height: 6rem;
}

.navmenu {
  display: flex;
}

.navitem {
  padding: 0.4rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.active {
  border: solid 0.075rem #685e5eb3;
  border-radius: 0.25rem;
}

.navicons {
  display: flex;
    list-style: none;
    justify-content: flex-end;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 3.5rem;
    font-size: 1.2rem;
    position: absolute;
    top: 0.1rem;
    right: 1rem;
}

.font-md{
  font-size: 0.95;
  transition: all 1s;

}


.navicons li {
  padding: 0.5rem;
  font-size: 1.1rem; 
}

.navicons-rg {
  margin-right: 4rem;
}

.btn-nav{
  background-color: black;
  font-family: inherit;
  font-size: 0.9rem;
  transition: all 1s;
}



.navLink {
  align-items: center;
  text-transform: uppercase;
  color: black;
  font-family: Open Sans;
  font-weight: 600;
  margin:0 auto;
  transition: all 1s;
  gap: 0.5rem;

}

.font-sm{
  font-size: 0.95rem;
  transition: all 1s;
}


.Cart {
  font-size: 2.3rem;
  margin-left: 1rem;
  display: flex;
  position: fixed;
  right: 21px;
  top: 1rem;
}

/* Section */

.flecha1 {
  height: 9.375rem;
  object-fit: cover;
}

.flecha2 {
  height: 9.375rem;
  object-fit: cover;
  transform: rotate(180deg);

}

.circle {
  background: #e6eaed;
  border-radius: 50%;
  width: 7.5rem;
  margin: 0 auto;
  transform: translateY(3.75rem);
  height: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container_title {
  color: #2e3f56;
  width: 100%;
  background: #e6eaed;
  padding-top: 3.5rem;
  padding-bottom: 2rem;
  list-style: 1.75rem;
}

.container_title h2 {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  text-transform: uppercase;
}

.container_title p {
  font-size: 1.3rem;
  width: 95%;
  font-weight: 500;
  margin: 0.5rem auto;
}

/* ESCUDOS */
.escudos {
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8rem;
}

.escudos img {
  width: 13.5rem;
  object-fit: cover;
  object-position: right;
}

.escudos > div > p{
font-weight:700;
font-size: 1.2rem;
}



.h-4 {
  transition: all 1s;
  scale: 0.9;
}

.slick-prev, .slick-next{
  display:none!important;
}

.slick-dots{
  bottom: 18px;
}